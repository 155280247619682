import React, { PropsWithChildren, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { UserStatus } from '@koeajacom/ka-types/dist/types';
import WaitWhileAuthenticating from '../common/WaitWhileAuthenticating';

interface ProtectedRouteProps {
  userStatus: UserStatus | null;
  to?: string;
}

const CustomerProtectedRoute = ({
  children,
  userStatus,
  to,
}: PropsWithChildren<ProtectedRouteProps>): ReactElement => {
  // User status header not yet parsed
  if (!userStatus) {
    return <WaitWhileAuthenticating />;
  }

  // User status header parsed, but no user logged in
  if (!userStatus.userID) {
    return <Navigate to={to!} replace />;
  }

  return children as ReactElement || <Outlet />;
};

CustomerProtectedRoute.defaultProps = {
  to: '/oauth/authorize',
};

export default CustomerProtectedRoute;
