import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './i18n';

import './scss/App.scss';

import ToasterProvider from './toaster/ToasterProvider';

import App from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <ToasterProvider>
      <App />
    </ToasterProvider>
  </BrowserRouter>
);
