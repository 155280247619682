import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState } from 'react';

// React Bootstrap components
import Button from 'react-bootstrap/Button';

import CustomerAPI from '../../api/customer';

import ActionSpinner from '../common/ActionSpinner';

interface StarOverButtonProps {
  api: CustomerAPI;
}

const StarOverButton = ({
  api,
}: StarOverButtonProps): ReactElement | null => {
  const [pending, setPending] = useState<boolean>(false);

  const { t } = useTranslation();

  const resetSelectedVehicle = () => {
    setPending(true);
    api.resetSelectedVehicle().catch(() => {});
  };

  return (
    <Button
      className="w-100"
      variant="danger"
      onClick={() => resetSelectedVehicle()}
      disabled={pending}
    >
      <ActionSpinner pending={pending} />
      {t('general-startOver')}
    </Button>
  );
};

export default StarOverButton;
