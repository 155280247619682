import { useTranslation } from 'react-i18next';
import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { types } from '@koeajacom/ka-types';

// React Bootstrap components
import Container from 'react-bootstrap/Container';

import { useToaster } from './toaster/ToasterContext';
import AgentAPI from './api/agent';

// Custom components
import AgentProtectedRoute from './components/agent/AgentProtectedRoute';
import Header from './components/agent/Header';

// Custom Views
// import Home from './views/agent/Home';
import TestDrivePermits from './views/agent/TestDrivePermits';
import Login from './views/agent/Login';
import Vehicles from './views/agent/Vehicles';

const AgentApp = (): ReactElement => {
  const [agentProfile, setAgentProfile] = useState<types.AgentProfileWithDealership | null>(null);
  const [onInitialFetch, setOnInitialFetch] = useState<boolean>(true);

  const { t } = useTranslation();
  const { showToast } = useToaster();

  const api = new AgentAPI(
    showToast,
    t
  );

  /**
   * Effect to fetch the agent profile from the backend on page reload.
   */
  useEffect(() => {
    api.me().then((me) => { setAgentProfile(me); setOnInitialFetch(false); })
      .catch(() => { setOnInitialFetch(false); });
  }, []);

  return (
    <Routes>
      <Route
        path="/*"
        element={(
          <>
            <Header agentProfile={agentProfile} setAgentProfile={setAgentProfile} api={api} />
            <Container fluid className="body-container">
              <Routes>
                <Route path="/" element={<Navigate to="/koeajot" replace />} />
                <Route path="/login" element={<Login agentProfile={agentProfile} api={api} setAgentProfile={setAgentProfile} />} />
                <Route element={(
                  <AgentProtectedRoute
                    onInitialFetch={onInitialFetch}
                    agentProfile={agentProfile}
                  />
                  )}
                >
                  <Route path="/koeajot" element={<TestDrivePermits api={api} agentProfile={agentProfile} />} />
                  <Route path="/ajoneuvot" element={<Vehicles api={api} />} />
                </Route>
              </Routes>
            </Container>
          </>
        )}
      />
    </Routes>
  );
};

export default AgentApp;
