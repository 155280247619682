/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface TermsAndConditionsModalProps {
  show: boolean;
  handleClose: () => void;
}

const TermsAndConditionsModal = ({
  show,
  handleClose,
}: TermsAndConditionsModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>
            {t('general-termsAndConditionsTitle')}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body dangerouslySetInnerHTML={{ __html: t('general-termsAndConditions') }} />
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>{t('general-close')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditionsModal;
