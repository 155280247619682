import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { UserStatus } from '@koeajacom/ka-types/dist/types';
import WaitForRedirect from '../../../components/customer/WaitForRedirect';
import CustomerAPI from '../../../api/customer';
import { APIError } from '../../../api/error';

interface AuthCodeProps {
  userStatus: UserStatus | null;
  api: CustomerAPI;
}

const AuthCode = ({
  userStatus,
  api,
}: AuthCodeProps): ReactElement => {
  const [error, setError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  // Navigated from withing app             -> Try to login
  //                                           This should never happen!
  //                                           This will always fail and result in an error!
  //                                           Show spinner while trying to login
  // Navigated from outside the app         -> Login
  //                                           Show spinner while logging in
  // Error while logging in                 -> Reset selected vehicle and after that redirect to /
  //                                           Show spinner while resetting the selected vehicle
  // Error while resetting selected vehicle -> Infinite spinner

  useEffect(() => {
    if (!userStatus?.userID) {
      const isbError = searchParams.get('error');
      if (isbError) {
        const errorDescriptionI18nKey = isbError === 'access_denied' ? 'auth-cancel' : 'auth-fail';
        api.toastError(new APIError(
          'Encountered an error while performing strong authentication.',
          errorDescriptionI18nKey
        ));
        setError(true);
        api.resetSelectedVehicle().catch(() => {});
      } else {
        const authorizationCode = searchParams.get('code')!;
        const state = searchParams.get('state')!;

        api.login({ authorizationCode, state }).catch(() => {
          setError(true);
          api.resetSelectedVehicle().catch(() => {});
        });
      }
    }
  }, []);

  if ((!userStatus?.userID && !error) || (error && userStatus?.vehicle)) {
    return <WaitForRedirect />;
  }

  return <Navigate to="/" replace />;
};

export default AuthCode;
