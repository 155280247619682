import React, { ReactElement } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const WaitWhileAuthenticating = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <h2 className="m-4">{t('general-wait-while-authenticating')}</h2>
      <Spinner className="ms-4" />
    </Container>
  );
};

export default WaitWhileAuthenticating;
