import React, { ReactElement } from 'react';

// React Bootstrap components
import Spinner from 'react-bootstrap/Spinner';

interface ActionSpinnerProps {
  pending: boolean;
  noSpace?: boolean;
}

const ActionSpinner = ({
  pending,
  noSpace,
}: ActionSpinnerProps): ReactElement | null => {
  if (pending) {
    return (
      <>
        <Spinner animation="border" variant="light" as="span" size="sm" />
        {noSpace ? null : (<>&nbsp;&nbsp;</>)}
      </>
    );
  }

  return null;
};

ActionSpinner.defaultProps = {
  noSpace: false,
};

export default ActionSpinner;
