import React, { ReactElement } from 'react';

// React Bootstrap components
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ToastProps } from './ToasterContext';

interface FriedToastProps {
  toast: ToastProps;
  setToast: (val: React.SetStateAction<ToastProps | null>) => void;
}

const FriedToast = ({
  toast,
  setToast,
}: FriedToastProps): ReactElement => (
  <Row>
    <Col xs={6}>
      <ToastContainer
        className="p-3"
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 9999,
        }}
      >
        <Toast
          onClose={() => setToast(null)}
          show={!!toast.message}
          delay={toast.fryTime}
          autohide
          bg={toast.type}
        >
          <Toast.Header>
            <strong className="me-auto">{toast.header}</strong>
          </Toast.Header>
          <Toast.Body className={toast.type === 'dark' || toast.type === 'danger' ? 'text-white' : ''}>{toast.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Col>
  </Row>
);

export default FriedToast;
