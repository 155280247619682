/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, {
  ReactElement,
  useState,
} from 'react';
import { Navigate } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { AgentProfileWithDealership } from '@koeajacom/ka-types/dist/types';

import AgentAPI from '../../api/agent';

import ActionSpinner from '../../components/common/ActionSpinner';

interface LoginProps {
  agentProfile: AgentProfileWithDealership | null;
  api: AgentAPI;
  setAgentProfile: (agentProfile: AgentProfileWithDealership) => void;
}

const Login = ({
  agentProfile,
  api,
  setAgentProfile,
}: LoginProps): ReactElement => {
  const [pending, setPending] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { t } = useTranslation();

  const login = () => {
    setPending(true);
    api.login({ emailAddress, password })
      .then((ap) => {
        setAgentProfile(ap);
        setPending(false);
      })
      .catch(() => setPending(false));
  };

  if (agentProfile?.ID) return <Navigate to="/" replace />;

  return (
    <Row className="justify-content-center py-2">
      <Col xs={11} md={10} lg={7} xxl={6}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
          className="mt-5"
        >
          <Form.Group className="mb-3">
            <Form.Label>{t('agent-login-emailAddress')}</Form.Label>
            <Form.Control type="email" placeholder={t('agent-login-emailAddress')} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t('agent-login-password')}</Form.Label>
            <Form.Control type="password" placeholder={t('agent-login-password')} value={password} onChange={(e) => setPassword(e.target.value)} />
          </Form.Group>
          <Button
            variant="primary"
            className="w-100"
            type="submit"
            disabled={pending || !emailAddress || !password}
          >
            <ActionSpinner pending={pending} />
            {t('agent-login-login')}
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
