import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import fi from './locales/fi.json';

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fi',
    debug: true,
    resources: {
      en: {
        translation: en,
      },
      fi: {
        translation: fi,
      },
    },
  });
