/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, PropsWithChildren } from 'react';

import { ToasterContext, ToastProps } from './ToasterContext';
import FriedToast from './FriedToast';

const ToasterProvider = ({ children }: PropsWithChildren) => {
  const [toast, setToast] = useState<ToastProps | null>(null);

  const showToast = (props: ToastProps): void => {
    setToast({
      fryTime: props.fryTime,
      header: props.header,
      message: props.message,
      type: props.type,
    });
    setTimeout(() => {
      setToast(null);
    }, props.fryTime);
  };

  return (
    <ToasterContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <FriedToast
          toast={toast}
          setToast={setToast}
        />
      )}
    </ToasterContext.Provider>
  );
};

export default ToasterProvider;
