import { createContext, useContext } from 'react';

import ToastType from './ToastType';

interface ToasterContextType {
  showToast: (props: ToastProps) => void;
}

export interface ToastProps {
  fryTime: number;
  header: string;
  message: string;
  type: ToastType;
}

export const ToasterContext = createContext<ToasterContextType | undefined>(
  undefined
);

export const useToaster = (): ToasterContextType => {
  const context = useContext(ToasterContext);
  if (!context) {
    throw new Error('useToaster must be used within a ToasterProvider');
  }
  return context;
};
