import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { UserStatus } from '@koeajacom/ka-types/dist/types';
import WaitForRedirect from '../../../components/customer/WaitForRedirect';
import CustomerAPI from '../../../api/customer';

interface AuthAuthorizeProps {
  userStatus: UserStatus | null;
  api: CustomerAPI;
}

const AuthAuthorize = ({
  userStatus,
  api,
}: AuthAuthorizeProps): ReactElement => {
  const [error, setError] = useState<boolean>(false);

  // Navigated from withing app             -> Fetch authorization URL and redirect to OPISB
  //                                           Show spinner while fetching authorization URL
  // Navigated from outside the app         -> Redirect to /
  // Error while fetching authorization URL -> Reset selected vehicle and after that redirect to /
  //                                           Show spinner while resetting the selected vehicle
  // Error while resetting selected vehicle -> Infinite spinner

  useEffect(() => {
    if (userStatus && !userStatus.userID) {
      api.getAuthorizationUrl()
        .then((url) => window.location.replace(url))
        .catch(() => {
          setError(true);
          api.resetSelectedVehicle().catch(() => {});
        });
    }
  }, []);
  if ((userStatus && !userStatus.userID && !error) || (error && userStatus?.vehicle)) {
    return <WaitForRedirect />;
  }

  return <Navigate to="/" replace />;
};

export default AuthAuthorize;
