/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useState, useEffect } from 'react';

import { types } from '@koeajacom/ka-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import AgentAPI from '../../api/agent';

import VehicleCard from '../../components/agent/VehicleCard';
import AddVehicleModal from '../../components/agent/AddVehicleModal';

interface VehiclesProps {
  api: AgentAPI;
}

const Vehicles = ({
  api,
}: VehiclesProps): ReactElement => {
  const [vehicles, setVehicles] = useState<types.DBVehicle[] | null>(null);
  const [pending, setPending] = useState<boolean>(true);
  const [showAddVehicleModal, setShowAddVehicleModal] = useState<boolean>(false);
  const [vehicleToEdit, setVehicleToEdit] = useState<types.DBVehicle | null>(null);

  const { t } = useTranslation();

  const handleCloseModal = () => {
    setShowAddVehicleModal(false);
    setVehicleToEdit(null);
  };

  const selectVehicleToEdit = (v: types.DBVehicle) => {
    setVehicleToEdit(v);
    setShowAddVehicleModal(true);
  };

  /**
   * Effect to fetch test drive permits from the backend on page reload.
   */
  useEffect(() => {
    api.getVehicles().then((vs) => { setVehicles(vs.vehicles); setPending(false); })
      .catch(() => { setPending(false); });
  }, []);

  if (pending || !vehicles) {
    return (
      <Alert variant="info">
        <Alert.Heading>
          <Spinner animation="border" as="span" />
          &nbsp;&nbsp;
          {t('agent-loading')}
        </Alert.Heading>
        <hr />
        <p>
          {t('agent-vehicles-loading')}
        </p>
      </Alert>
    );
  }

  return (
    <>
      {showAddVehicleModal
        && (
          <AddVehicleModal
            show
            vehicle={vehicleToEdit}
            api={api}
            setVehicles={setVehicles}
            handleClose={handleCloseModal}
          />
        )}
      <Button
        className="w-100 mt-2"
        onClick={() => setShowAddVehicleModal(true)}
      >
        {t('agent-vehicles-button-new')}
      </Button>
      {vehicles.length === 0 ? (
        <Alert variant="info" className="mt-2">
          {t('agent-vehicles-noVehicles')}
        </Alert>
      ) : (
        <Row className="justify-content-center py-2">
          <Col xs={12} md={10} lg={7} xxl={6}>
            {vehicles.map((v) => (
              <VehicleCard
                api={api}
                v={v}
                setVehicles={setVehicles}
                setVehicleToEdit={selectVehicleToEdit}
                key={v.ID}
              />
            ))}
          </Col>
        </Row>
      )}
    </>
  );
};

export default Vehicles;
