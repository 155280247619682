import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { UserStatus } from '@koeajacom/ka-types/dist/types';

// React Bootstrap components
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';

interface HeaderMenuProps {
  userStatus: UserStatus | null;
  setExpanded: (expanded: boolean) => void;
}

const HeaderMenu = ({
  userStatus,
  setExpanded,
}: HeaderMenuProps): ReactElement => {
  const { t } = useTranslation();

  if (!userStatus || !userStatus.userID) {
    return (
      <Nav className="justify-content-end pe-1">
        <Nav.Link as={Link} to="/oauth/authorize" className="p-0">
          <Button variant="primary">
            {t('navbar-login')}
          </Button>
        </Nav.Link>
      </Nav>
    );
  }

  return (
    <>
      <Navbar.Toggle className="bg-primary" />
      <Navbar.Offcanvas
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {`${userStatus.profile?.firstName} ${userStatus.profile?.lastName}`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1">
            <Nav.Link as={Link} to="/me" onClick={() => setExpanded(false)}>
              {t('navbar-me')}
            </Nav.Link>
            <Nav.Link as={Link} to="/logout" className="navbar-nav">
              <Button variant="primary">
                {t('navbar-logout')}
              </Button>
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </>
  );
};

export default HeaderMenu;
