import React, { PropsWithChildren, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { types } from '@koeajacom/ka-types';
import WaitWhileAuthenticating from '../common/WaitWhileAuthenticating';

interface ProtectedRouteProps {
  agentProfile: types.AgentProfileWithDealership | null;
  onInitialFetch: boolean;
  to?: string;
}

const AgentProtectedRoute = ({
  children,
  agentProfile,
  onInitialFetch,
  to,
}: PropsWithChildren<ProtectedRouteProps>): ReactElement => {
  // User status header not yet parsed
  if (onInitialFetch) {
    return <WaitWhileAuthenticating />;
  }

  // User status header parsed, but no user logged in
  if (!agentProfile || !agentProfile.ID) {
    return <Navigate to={to!} replace />;
  }

  return children as ReactElement || <Outlet />;
};

AgentProtectedRoute.defaultProps = {
  to: '/login',
};

export default AgentProtectedRoute;
