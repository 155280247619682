import React, { ReactElement } from 'react';

// Apps
import CustomerApp from './CustomerApp';
import AgentApp from './AgentApp';

const App = (): ReactElement => {
  const domainParts = window.location.host.split('.');
  const subDomain = domainParts[0];

  if (subDomain === 'agent') {
    return (
      <AgentApp />
    );
  }

  return (
    <CustomerApp />
  );
};

export default App;
