import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { UserStatus } from '@koeajacom/ka-types/dist/types';
import WaitForRedirect from '../../../components/customer/WaitForRedirect';
import CustomerAPI from '../../../api/customer';

interface AuthLogoutProps {
  userStatus: UserStatus | null;
  api: CustomerAPI;
}

const AuthLogout = ({
  userStatus,
  api,
}: AuthLogoutProps): ReactElement => {
  const [error, setError] = useState<boolean>(false);

  // Navigated from withing app     -> Logout
  //                                   Show spinner while logging out
  // Navigated from outside the app -> Redirect to /
  // Error while logging out        -> Redirect to /

  useEffect(() => {
    if (userStatus && userStatus.userID) {
      api.logout().catch(() => setError(true));
    }
  }, []);

  if (userStatus && userStatus.userID && !error) {
    return <WaitForRedirect />;
  }

  return <Navigate to="/" replace />;
};

export default AuthLogout;
