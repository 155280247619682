import { useTranslation } from 'react-i18next';
import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

import { types } from '@koeajacom/ka-types';

// React Bootstrap components
import Container from 'react-bootstrap/Container';

import { useToaster } from './toaster/ToasterContext';
import CustomerAPI from './api/customer';

// Custom components
import CustomerProtectedRoute from './components/customer/CustomerProtectedRoute';
import Header from './components/customer/Header';

// Custom Views
import AuthLogout from './views/customer/auth/AuthLogout';
import AuthAuthorize from './views/customer/auth/AuthAuthorize';
import Home from './views/customer/Home';
import Koeaja from './views/customer/Koeaja';
import Koeajo from './views/customer/Koeajo';
import AuthCode from './views/customer/auth/AuthCode';

const CustomerApp = (): ReactElement => {
  const [userStatus, setUserStatus] = useState<types.UserStatus | null>(null);

  const { t } = useTranslation();
  const { showToast } = useToaster();

  const location = useLocation();

  const api = new CustomerAPI(
    showToast,
    setUserStatus,
    t
  );

  /**
   * Effect to fetch the userStatus header from the backend on page reload.
   */
  useEffect(() => {
    // Exclude /oauth/code to prevent fetching empty userStatus header using old cookie while
    // in the middle of authenticating.
    if (location.pathname !== '/oauth/code') api.getUserStatus().catch(() => {});
  }, []);

  return (
    <Routes>
      <Route path="/oauth/authorize" element={<AuthAuthorize userStatus={userStatus} api={api} />} />
      <Route path="/oauth/code" element={<AuthCode userStatus={userStatus} api={api} />} />
      <Route path="/logout" element={<AuthLogout userStatus={userStatus} api={api} />} />
      <Route
        path="/*"
        element={(
          <>
            <Header userStatus={userStatus} />
            <Container fluid className="body-container">
              <Routes>
                <Route path="/" element={<Home userStatus={userStatus} api={api} />} />
                <Route element={(<CustomerProtectedRoute userStatus={userStatus} />)}>
                  <Route path="/koeaja" element={<Koeaja userStatus={userStatus} api={api} />} />
                  <Route path="/koeajo" element={<Koeajo userStatus={userStatus} api={api} />} />
                </Route>
              </Routes>
            </Container>
          </>
        )}
      />
    </Routes>
  );
};

export default CustomerApp;
