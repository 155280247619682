import { enums, types } from '@koeajacom/ka-types';
import { TFunction } from 'i18next';

import { ToastProps } from '../toaster/ToasterContext';

import BaseAPI from './api';

/** This class is responsible for the backend communication.
 *
 * Each api endpoint has its own method here in API. Methods are returning promises that resolve
 * only after the backend replies with an accepting status code. If anything goes wrong during the
 * request, the promises returned from the API methods should reject. In that case, the API takes
 * care of displaying an informative and internationalized error toast for the user.
 */
export default class CustomerAPI extends BaseAPI {
  constructor(
    showToast: (props: ToastProps) => void,
    setUserStatus: (userStatus: types.UserStatus) => void,
    i18n: TFunction
  ) {
    super(showToast, setUserStatus, i18n);

    this.axiosInstance.defaults.baseURL = '/api/customer';
  }

  /**
   * How api endpoints are implemented:
   *
   * The API methods depend on the axios library. One notable difference is that the axios will
   * resolve with any response status code, even with the 5xx ones. The status code based validation
   * must be done in separately each method.
   *
   * To make it simpler, there exists three utility functions: reject, acceptOnly and inspectError.
   * The intended usage for these is somewhat similar to the factory model approach; but the
   * chaining of the rules is done using the native chaining methods of js Promises.
   *
   * Each method start with an axios call. The utility functions are then chained to the promise it
   * returns. The logic behind those rules tries to imitate a simple firewall rule set. They should
   * be chained in the following order:
   *   1. reject (blacklists a single response with an known error status code) (optional)
   *   2. acceptOnly (specifies a whitelist for responses to be accepted)
   *   3. inspectError (catches rejections and toasts an appropriate error message for the user)
   *
   * In most scenarios, only the acceptOnly and inspectError functions are required. Note, that one
   * reject or acceptOnly block can give only errors with one specific message. For example, the
   * acceptOnly rejects the responses with the same error message, so if some response status code
   * should lead to a more specific error message, a reject can be chained before the acceptOnly.
   *
   * Inspect error is always required and should be chained using Promise.catch. This way it catches
   * all errors thrown during any part of the promise chain, also the ones thrown by axios.
   *
   * More specific information about the utility functions can be found on their documentation.
   */

  /** Fetch the userStatus header. Used i.e. after page reload to sync status with the backend. */
  async getUserStatus(): Promise<void> {
    return this.axiosInstance.get('/userStatus')
      .then(this.acceptOnlyWithStatus<void>(204, 'api-userStatus-fail'))
      .catch(this.inspectError());
  }

  /** Fetch an authorization URL where the user can be redirected for strong authentication */
  async getAuthorizationUrl(): Promise<types.AuthorizationURL> {
    return this.axiosInstance.get('/oauth/authorize')
      .then(this.acceptOnlyWithStatus<types.AuthorizationURL>(200, 'api-authorizationUrl-fail'))
      .catch(this.inspectError());
  }

  /** Post the authorization code and state received from ISB to backend for login */
  async login(authorizationCode: types.AuthorizationCode): Promise<void> {
    return this.axiosInstance.post('/oauth/code', authorizationCode)
      .then(this.reject(401, (res) => ({ message: `Strong authentication failed: ${res.data.reason}`, i18nKey: 'auth-fail' })))
      .then(this.acceptOnlyWithStatus<void>(204, 'api-authentication-fail'))
      .catch(this.inspectError());
  }

  /** Log out the user */
  async logout(): Promise<void> {
    return this.axiosInstance.post('/logout')
      .then(this.acceptOnlyWithStatus<void>(204, 'api-logout-fail'))
      .catch(this.inspectError());
  }

  /** Select the vehicle for this session */
  async selectVehicle(licensePlate: string): Promise<void> {
    return this.axiosInstance.post(`/selectVehicle/${licensePlate}`)
      .then(this.reject(404, { message: 'Vehicle not found', i18nKey: 'api-selectVehicle-not-found' }))
      .then(this.acceptOnlyWithStatus<void>(204, 'api-selectVehicle-fail'))
      .catch(this.inspectError());
  }

  /** Select the vehicle for this session */
  async resetSelectedVehicle(): Promise<void> {
    return this.axiosInstance.delete('/selectVehicle')
      .then(this.acceptOnlyWithStatus<void>(204, 'api-selectVehicle-fail'))
      .catch(this.inspectError());
  }

  /** Update the user profile */
  async updateProfile(newProfile: types.ProfileUpdate): Promise<void> {
    return this.axiosInstance.patch('/profile', newProfile)
      .then(this.acceptOnlyWithStatus<void>(204, 'api-updateProfile-fail'))
      .catch(this.inspectError());
  }

  /** Check driver's license */
  async checkDriversLicense(): Promise<void> {
    return this.axiosInstance.post('/checkDriversLicense')
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.DriversLicenseCheckErrorReason.CurrentOrUpcomingTestDrivePermitAlreadyExists:
            return { message: 'Current or upcoming test drive permit already exists', i18nKey: 'api-checkDriversLicense-currentOrUpcomingTestDrivePermitAlreadyExists' };
          case enums.DriversLicenseCheckErrorReason.TraficomFailed:
            return { message: 'Traficom failed', i18nKey: 'api-checkDriversLicense-traficomFailed' };
          case enums.DriversLicenseCheckErrorReason.NoVehicleSelected:
            return { message: 'No vehicle selected', i18nKey: 'api-checkDriversLicense-noVehicleSelected' };
          case enums.DriversLicenseCheckErrorReason.DriversLicenseAlreadyChecked:
            return { message: 'Driver\'s license already verified', i18nKey: 'api-checkDriversLicense-driversLicenseAlreadyChecked' };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-checkDriversLicense-fail' };
        }
      }))
      .then(this.acceptOnlyWithStatus<void>(204, 'api-checkDriversLicense-fail'))
      .catch(this.inspectError());
  }

  /** Check credit info */
  async checkCreditInfo(): Promise<void> {
    return this.axiosInstance.post('/checkCreditInfo')
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.CreditInfoCheckErrorReason.CurrentOrUpcomingTestDrivePermitAlreadyExists:
            return { message: 'Current or upcoming test drive permit already exists', i18nKey: 'api-checkCreditInfo-currentOrUpcomingTestDrivePermitAlreadyExists' };
          case enums.CreditInfoCheckErrorReason.AsiakastietoFailed:
            return { message: 'Asiakastieto failed', i18nKey: 'api-checkCreditInfo-asiakastietoFailed' };
          case enums.CreditInfoCheckErrorReason.NoVehicleSelected:
            return { message: 'No vehicle selected', i18nKey: 'api-checkCreditInfo-noVehicleSelected' };
          case enums.CreditInfoCheckErrorReason.DriversLicenseNotChecked:
            return { message: 'Driver\'s license not verified', i18nKey: 'api-checkCreditInfo-driversLicenseNotChecked' };
          case enums.CreditInfoCheckErrorReason.CreditInfoAlreadyChecked:
            return { message: 'Credit info already verified', i18nKey: 'api-checkCreditInfo-creditInfoAlreadyChecked' };
          case enums.CreditInfoCheckErrorReason.CreditInfoCheckNotEnabled:
            return { message: 'Credit info verification not enabled', i18nKey: 'api-checkCreditInfo-creditInfoCheckNotEnabled' };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-checkCreditInfo-fail' };
        }
      }))
      .then(this.acceptOnlyWithStatus<void>(204, 'api-checkCreditInfo-fail'))
      .catch(this.inspectError());
  }

  /** Fetch available time slots for the selected date */
  async getTimeSlots(date: string): Promise<types.TestDriveTimeSlots> {
    return this.axiosInstance.get(`/timeSlots/${date}`)
      .then(this.acceptOnlyWithStatus<types.TestDriveTimeSlots>(200, 'api-timeSlots-fail'))
      .catch(this.inspectError());
  }

  /** Apply for test drive permit */
  async applyForPermit(timeSlot: types.TestDriveTimeSlotWithDate): Promise<void> {
    return this.axiosInstance.post('/applyForPermit', timeSlot)
      .then(this.reject(404, { message: 'Vehicle not found', i18nKey: 'api-selectVehicle-not-found' }))
      .then(this.reject(409, (res) => {
        switch (res.data.reason) {
          case enums.CreateTestDrivePermitErrorReason.CurrentOrUpcomingTestDrivePermitAlreadyExists:
            return { message: 'Current or upcoming test drive permit already exists', i18nKey: 'api-applyForPermit-currentOrUpcomingTestDrivePermitAlreadyExists' };
          case enums.CreateTestDrivePermitErrorReason.NoVehicleSelected:
            return { message: 'No vehicle selected', i18nKey: 'api-applyForPermit-noVehicleSelected' };
          case enums.CreateTestDrivePermitErrorReason.DriversLicenseNotChecked:
            return { message: 'Driver\'s license not verified', i18nKey: 'api-applyForPermit-driversLicenseNotChecked' };
          case enums.CreateTestDrivePermitErrorReason.CreditInfoNotChecked:
            return { message: 'Credit info not verified', i18nKey: 'api-applyForPermit-creditInfoNotChecked' };
          case enums.CreateTestDrivePermitErrorReason.TimeSlotOutsideOpeningHours:
            return { message: 'Time slot outside opening hours', i18nKey: 'api-applyForPermit-timeSlotOutsideOpeningHours' };
          case enums.CreateTestDrivePermitErrorReason.TimeSlotOverlapsWithExistingTestDrivePermit:
            return { message: 'The vehicle is reserved during the selected time slot', i18nKey: 'api-applyForPermit-timeSlotOverlapsWithExistingTestDrivePermit' };
          case enums.CreateTestDrivePermitErrorReason.UserProfileMissingDetails:
            return { message: 'User profile missing details', i18nKey: 'api-applyForPermit-userProfileMissingDetails' };
          default:
            return { message: 'Unknown 409 error', i18nKey: 'api-applyForPermit-fail' };
        }
      }))
      .then(this.acceptOnlyWithStatus<void>(204, 'api-applyForPermit-fail'))
      .catch(this.inspectError());
  }
}
