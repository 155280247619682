import React, { useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { UserStatus } from '@koeajacom/ka-types/dist/types';

// React Bootstrap components
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

// Custom components
import HeaderMenu from './HeaderMenu';
import HeaderLanguageSelector from '../common/HeaderLanguageSelector';

interface HeaderProps {
  userStatus: UserStatus | null;
}

const Header = ({
  userStatus,
}: HeaderProps): ReactElement => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar
      bg="light"
      data-bs-theme="light"
      expand="false"
      sticky="top"
      onToggle={setExpanded}
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="ps-1 me-auto">
          <img
            alt="logo"
            src="img/koeajacom_symbol.png"
            height="30"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <HeaderLanguageSelector />
        <HeaderMenu userStatus={userStatus} setExpanded={setExpanded} />
      </Container>
    </Navbar>
  );
};

export default Header;
