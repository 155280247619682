import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';
import ReactCountryFlag from 'react-country-flag';

// React Bootstrap components
import Dropdown from 'react-bootstrap/Dropdown';

const HeaderLanguageSelector = (): ReactElement => {
  const { i18n } = useTranslation();

  return (
    <Dropdown className="pe-2">
      <Dropdown.Toggle className="language-toggle">
        <ReactCountryFlag countryCode={i18n.language.replace(/-[A-Za-z]{2}/, '').replace('en', 'gb')} svg className="flag" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          className="fw-bold fs-6"
          onClick={() => {
            i18n.changeLanguage('fi');
          }}
        >
          <ReactCountryFlag countryCode="fi" svg className="flag" />
          &nbsp;
          Suomi
        </Dropdown.Item>
        <Dropdown.Item
          className="fw-bold"
          onClick={() => {
            i18n.changeLanguage('en');
          }}
        >
          <ReactCountryFlag countryCode="gb" svg className="flag" />
          &nbsp;
          English
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

  );
};

export default HeaderLanguageSelector;
